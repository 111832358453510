import React from 'react';
import { navigate } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import TemplatePDF from '../files/project-briefing-form.pdf'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'order',
        ...this.state
      })
    })
      .then(() => {
        navigate('/thanks');
      })
      .catch(error => alert(error));
  };

  render() {
    return (
      <Layout>
        <SEO
          title="Contact Us"
        />
        <section className="section section--gradient">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <h2 className="title is-size-3 has-text-weight-bold is-bold-light has-underline">
                  Order
                </h2>
              </div>
            </div>
            <div className="columns">
              <div className="column is-5 is-offset-1 content">
                <h3 className="title is-size-4">Stand Design Brief Template</h3>
                <p className="is-size-4">
                  If you’d like to brief us on a stand design, you can download and complete our stand design brief template:
                </p>
                <p>
                  <a
                    href={TemplatePDF}
                    target="_blank"
                    className="button is-success">
                    Download
                  </a>
                </p>
              </div>
              <div className="column is-5">
                <h3 className="title is-size-4">Email Us</h3>
                <form
                  name="order"
                  method="post"
                  action="/thanks/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={this.handleSubmit}
                >

                  <input type="hidden" name="form-name" value="contact" />
                  <p hidden>
                    <label>
                      Don’t fill this out:{" "}
                      <input name="bot-field" onChange={this.handleChange} />
                    </label>
                  </p>

                  <div className="field">
                    <div className="control">
                      <input className="input" type="text" name="name" onChange={this.handleChange} placeholder="Your Name" />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input className="input" type="text" name="company" onChange={this.handleChange} placeholder="Your Company" />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input className="input" type="text" name="email" onChange={this.handleChange} placeholder="Your Email" />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input className="input" type="text" name="telephone" onChange={this.handleChange} placeholder="Telephone" />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input className="input" type="text" name="event" onChange={this.handleChange} placeholder="Event" />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <textarea className="textarea" type="text" name="additional_information" onChange={this.handleChange} placeholder="Additional Information" />
                    </div>
                  </div>
                  <p>
                    <button className="button is-success" type="submit">Send</button>
                  </p>
                </form>

              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
